enum PageType {
  Article = 'article',
  ArticleMatch = 'article-match',
  ArticlePodcast = 'article-podcast',
  ArticleVideo = 'article-video',
  Category = 'category',
  Competition = 'competition',
  Home = 'home',
  Player = 'player',
  Podcast = 'podcast',
  PodcastEpisode = 'podcast-episode',
  PodcastProgram = 'podcast-program',
  Promo = 'page-promo',
  Team = 'team',
  Theme = 'theme',
}

export {
  PageType
}
